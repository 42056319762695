export const portfolioDetails =  {
  'Some Things I have Done': {
    //date: '2018 May - Present',
    details:[
      ['Fiigo', 'https://fiigo.co', 'Built Landing Page from existing User Interface Design — Fiigo offers employee benefits package for small businesses and independent contractors while maintaining a healthy work-life balance.', ''],
      ['Blueroomcare', 'https://blueroomcare.com', 'Developed Telehealth Web Application from scratch — a secure online platform to help those facing life’s challenges by providing easy, affordable and private access to professional counselling.', ''],
      ['Signalwire Developer\'s Documentation ', 'https://developer.signalwire.com/apis/docs/what-is-webrtc', 'I contributed to Signalwire\'s Documentation by writing a Technical article on \'Exploration to WebRTC\' — Signalwire is a next-generation video collaboration platform powered by an elastic cloud communications network featuring APIs for voice, SMS, and video functionality.', ''],
      ['i-Fitness', 'https://ifitness.ng', 'Built the company\'s website that allows Gym membership subscriptions with payment integration and also its E-Commerce store — i-Fitness is Nigeria’s first and fastest-growing fitness chain.', ''],
      ['Mesh Hairline E-Commerce Store', 'https://meshhairline.com', 'Designed and Developed the business E-Commerce website with multi-currency feature — Mesh Hairline is a unique Hair Brand founded with the desire and need to create a difference in the hair industry by providing the best luxury hair.', ''],
      ['vCard QR Code Generator', 'https://francis.rest/vCard', 'A web application that helps you generate vCard in QR Code Format. This was created with PHP QR Code open-source (LGPL) Library. ', ''],
      ['DSDmart Marketplace', 'https://dsdmart.com', 'Worked on implementing new UI changes and added functionalities to DSDMart Marketplace. It includes effective changes to Multi-currency Implementation, Custom Cart and Checkout Headers, Algolia Integration, PWA Migration.', ''],
      ['Gavia Luxury Store', 'https://gavialuxury.com', 'Designed and Developed the business E-Commerce website with multi-currency feature — Gavia Luxury offers complete range of cosmetic, wellness and beauty products.', '']

    ]
  },
  'Open Source': {
    date: '',
    details:[
      ['Password Generator',       'https://passgen-akf.netlify.app', 'Progressive Web Application that automatically generates a password based on guidelines that you set to create strong and unpredictable passwords.'],
      ['Github Profile Finder',          'https://githubfinder-akf.vercel.app', 'A better look at your GitHub profile and repo stats. Includes data visualizations of your top languages, starred repositories, and sort through your top repos by number of stars, forks, and size.'],
      ['COVID-19 Tracker', 'https://covid19-ng.vercel.app', 'Web-based statistic counter. It helps you stay updated with the latest statistics of all confirmed, deceased, active and recovered cases of coronavirus patients in Nigeria.']
    ]
  }
}

export const ABOUTME = {
  Experiences: [
    {
      'name'                  : 'Blueroomcare',
      'role'                  : 'Software Engineer',
      'period'                : 'Dec 2021 - Present',
    },
    {
    'name'                  : 'Ingressive For Good',
    'role'                  : 'Community Manager',
    'period'                : 'July 2020 - Present',
    },
    {
    'name'                  : 'ROTechnica',
    'role'                  : 'Frontend Engineer',
    'period'                : 'January 2019 - Present',
    },
   ],
  Education: [
    {
    'name'                  : 'Federal University of Technology, Akure',
    'branch'                : 'B.Tech. Information Technology',
    'period'                : '2018-2021',
    'project'               : 'In View'
    }
  ]
};

export const technicalSkills1 = [
  ['React'],
  ['JavaScript (ES6+)'],
  ['PHP'],
  ['JQuery'],
  ['Gatsby'],
  ['Gulp']
]

export const technicalSkills2 = [
  ['WordPress'],
  ['Jekyll'],
  ['Technical Writing'],
  ['SEO (On-Page & Off-Page)'],
  [''],
  ['']
];

export const introduction = {
  'description': ['> Hello there!',
                '> I\'m Francis Akoji, as you know already 😉',
                '> I\'m a Software Engineer at Blueroomcare where I communicate with multi-disciplinary teams of Developers, Product Designers, and Product Managers.',
                '> I am a Techie  specialized in building (and occasionally designing) exceptional websites, web applications, and everything in between.',
                '> I also Blog about some Interesting (Technical) stuffs here or @ROTechnica',
                '> What other things I do you ask?',
                '> Well...',
                '> I love Playing Video Games, Listening to Music (Santandave stan!), and watching Football.',
                '> Well that\'s pretty much about me!']
}
